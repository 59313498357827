/****************************************************
******* Breadcrumb (mostly on 2-column pages) *******
****************************************************/
.breadcrumbs {
    padding-top: .33em;
    font-size: $font-size-small;

    span {
        display: inline-block;
        padding-bottom: .25em;
    }

    .single-product & {
        span>span[property="name"] {
            display: none;
        }
    }
}
