.woocommerce {
    &.single-product {
        .breadcrumbs {
            span {
                > span[property="name"] {
                    @media screen and (max-width: $screen-sm-max) {
                        display: none;
                    }
                }
            }
        }
        #content,
        #main {
            @media screen and (min-width: $screen-is-min) {
                padding-top: .5em;
            }

            > .product {
                @include make-row();

                .woocommerce-product-gallery {
                    overflow: hidden;
                    text-align: center;
                    margin: 1em 0 0;

                    @media screen and (max-width: $screen-xs-max) {
                        float: none;
                        width: 100%;
                    }

                    @media screen and (min-width: $screen-is-min) and (orientation: landscape) and (max-width: $screen-is-max){
                        width: 40%;
                        float: left;
                    }

                    @include make-sm-column(5);


                    figure.woocommerce-product-gallery__wrapper {
                        margin-bottom: 1em;

                        img {
                            display: inline-block;
                            width: auto;
                            max-height: 600px;

                            @media screen and (max-width: $screen-is-max) {
                                max-height: 300px;
                            }
                        }
                    }
                    .flex-control-nav {
                        margin: 1em 0;

                        li {
                            width: 15.8%;
                            margin: .5%;

                            &:nth-child(4n+1) {
                                clear: none;
                            }

                            @media screen and (max-width: $screen-is-max) {
                                &:first-child, &:nth-child(6n+1) {
                                    margin-left: 0;
                                }
                                &:nth-child(6n+0) {
                                    margin-right: 0;
                                }
                            }

                            @media screen and (min-width: $screen-sm-min) {
                                width: 24.25%;
                                margin: .5%;

                                &:first-child, &:nth-child(4n+1) {
                                    margin-left: 0;
                                }
                                &:nth-child(4n+0) {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }

                .summary {
                    @media screen and (max-width: $screen-xs-max) {
                        float: none;
                        width: 100%;
                    }
                    @media screen and (min-width: $screen-is-min) and (orientation: landscape) and (max-width: $screen-is-max){
                        width: 56%;
                        float: left;
                        margin-left: 4%;
                    }
                    @include make-sm-column(7);

                    .product_title.entry-title {
                        font-size: $font-size-large;

                        @media screen and (min-width: $screen-md-min) {
                            font-size: $font-size-h2;
                        }
                    }

                    .price {
                        display: inline-block;
                        margin-bottom: 0;

                        &.price-unit {
                            display: inline-block;
                            margin-left: .5em;
                            color: $text-color;
                            font-size: $font-size-tiny;
                        }

                        ins {
                            text-decoration: none;
                        }
                    }

                    .product-units {
                        display: block;
                    }

                    form.cart {
                        margin-bottom: 0;
                    }

                    table.variations {
                        margin-bottom: 0;

                        .label, .value {
                            display: block;
                        }
                        .reset_variations {
                            display: block !important;
                        }
                    }

                    .stock {
                        &::before {
                            display: inline-block;
                            margin-right: .25em;
                            width: .75em;
                            height: .75em;
                            background: $color-success-lighten;
                            content: '';
                        }

                        &.out-of-stock {
                            color: $color-primary;

                            &::before {
                                background: $color-primary;
                            }
                        }
                    }

                    .single_add_to_cart_button {
                        padding-top: 12px;
                        padding-bottom: 12px;
                    }

                    .add_to_wishlist {
                        margin-left: 5em;
                    }

                    .product_meta {
                        > span {
                            display: block;
                        }
                    }
                }

                .woocommerce-tabs {

                    .woocommerce-Tabs-panel {
                        padding-left: $grid-gutter-width/2;
                        padding-right: $grid-gutter-width/2;

                        h3 {
                            @media screen and (max-width: $screen-is-max) {
                                font-size: 1.25em;
                            }
                        }

                        .shop_attributes {
                            text-align: left;
                        }

                        table {
                            border-top: none;

                            tr {
                                &:first-child {
                                    td,
                                    th {
                                        border-top: none;
                                    }
                                }
                                &:nth-child(even) {
                                    background: rgba($color-tertiary, 0.25);
                                }
                                td,
                                th {
                                    background: none;
                                    border-top: 1px solid #ddd;
                                }
                            }
                        }
                    }
                }

                .products {
                    &.related {
                        clear: both;
                        padding-top: .5em;
                        border-top: 1px solid $gray-lighter;

                        > h2 {
                            @include make-xs-column(12);
                        }

                        li.product {
                            @include make-xs-column(6);
                            @include make-sm-column(4);
                            position: relative;
                            margin: 0 0 2em;
                            min-height: 300px;
                            text-align: center;

                            @media screen and (max-width: $screen-xs-max){
                                &:nth-of-type(2n+1) {
                                    clear: left !important;
                                }
                            }


                            @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
                                &:nth-of-type(2n+1) {
                                    clear: none !important;
                                }
                            }

                            @media screen and (min-width: $screen-md-min) {
                                &:nth-of-type(3n+1) {
                                    clear: left !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.remodal {
    padding: 1.25em !important;
}
