.woocommerce-account {
    .woocommerce-MyAccount-navigation {
        width: auto;
        float: none;
    }

    .right-off-canvas-menu {
        .back-to-shop i {
            padding-right: .25em;
        }
    }
    
    .woocommerce-MyAccount-content {
        width: auto;
        float: none;
    }
    form {
        &.register,
        &.login{
            @media screen and (max-width: $screen-is-max) {
                label {
                    display: block;
                }
                .button {
                    font-size: $font-size-small;
                }
            }
        }
        &.login {
            label.inline {
                display: block;
                margin-top: .5em;
            }
        }
    }
}
