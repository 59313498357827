body {

}

.content-wrap,
#tinymce {
    h1,
    h2,
    h3,
    h4 {
    }

    h1,
    .h1{
        @media screen and (max-width: $screen-is-max) {
            font-size: 32px;
        }
    }

    .h1 {
        p {
            margin: 0;
        }
    }

    h2 {

    }

    h3 {

    }

    h4 {

    }
}

.slideshow-title {
    font-family: $font-family-special;
    font-size: $font-size-h1;
}

.tagline,
.tagline h1,
.uabb-subheading {
    color: #333;
    font-family: $headings-font-family;
    font-size: $font-size-big;
    line-height: $line-height-base;

    @media screen and (max-width: $screen-is-max) {
        line-height: $line-height-small;
        font-size: 20px;
    }
}
