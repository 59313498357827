// Search form
.search-form {
    @extend .form-inline;
}
.search-form label {
    @extend .form-group;
    font-weight: normal;
}
.search-form .search-field {
    @extend .form-control;
}
.search-form .search-submit {
    @extend .btn;
    @extend .btn-default;
}

// Caldera Forms
.caldera-grid {
    .form-group {
        &.has-error {

        }

        .control-label,
        .field_required {
            display: none;
            color: $color-primary !important;

            .no-placeholder &{
                display: inline-block;
            }
        }
    }

    textarea,
    textArea {
      min-width: 100%;
      max-width: 100%;
      min-height: 6em;
    }

    .alert.alert-success {
        padding: 1em;
        background: #fff;
        background: rgba(255, 255, 255, 0.75);
        color: $color-secondary;
        text-align: center;
        border-radius: 0;
        border:none;
        text-shadow: none;

        @media screen and (min-width: $screen-sm-min) {
            padding: 2em;
        }
    }

    input[type='checkbox'] {
        top: 4px;
    }

    .caldera-forms-summary-field {
        ul {
            @include clearfix();
            display: table;
            max-width: 600px;
            margin: 1em auto 2em;
            padding: 1em;
            background: #fff;

            li {
                display: table-row;
                width: 100%;

                &:nth-child(even) {
                    background: $color-tertiary;
                }

                span {
                    display: table-cell;
                    padding: .5em 1em;
                    &.caldera-forms-summary-label {
                        white-space: nowrap;
                    }
                    &.caldera-forms-summary-value {}
                }
            }
        }
    }
}
