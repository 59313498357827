.woocommerce {
    // Wishlist table styles
    table {
        &.wishlist_table {
            tr {
                &.cart_item {
                    &:nth-child(even) {
                        background: $color-tertiary;
                    }
                }
            }

            th,
            td {
                &.actions,
                &.wishlist-empty {
                    text-align: center !important;

                    &::before {
                        display: none;
                    }
                }
            }

            tfoot {
                display: none;
            }

            a.remove_from_wishlist,
            a.remove_from_wishlist:hover {
                background: none !important;
                color: $color-primary !important;
            }
        }
    }

    ul.wishlist_view.responsive.mobile {
        li {
            padding: 1em 0;
            border-bottom: 1px solid #ccc;

            &:last-child {
                border: none;
            }

            .product-name h3 {
                font-size: 1em;
            }
    
            .product-add-to-cart {
                float: right;
            }

            .additional-info-wrapper {
                .additional-info {
                    margin-left: 73px;
                }
    
                &::after {
                    display: table;
                    clear: both;
                    content: "";
                }
    
                .product-remove {
                    float: right;
                    margin-right: 2em;
                }
            }
        }
    }
}
