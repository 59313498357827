/****************************************************
*************** Global Helper Classes ***************
****************************************************/

// Covered and centered background-image
.bg-img-cover {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

// vertically and horizontally centered box (absolute - relative to parent)
.centered-box {
    @extend .container;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

// control the height of the hero content
.hero-area-content {
    min-height: 250px;

    @media screen and (min-width: $screen-sm-min){
        min-height: 300px;
    }

    @media screen and (min-width: $screen-md-min){
        min-height: 350px;
    }

    @media screen and (min-width: $screen-lg-min){
        min-height: 400px;
    }

    @media screen and (min-width: 2000px){
        min-height: 550px;
    }
}

// Horizontal aligned lists in content areas 50%/33.33%
.horizontal-list {
    @include list-unstyled();
    @include clearfix();

  li {
      padding: .25em;

      @media screen and (min-width: $screen-is) {
          width: calc(50% - 15px);
          float: left;

          &:nth-child(odd) {
              clear: left;
          }

          &:nth-child(even) {
              margin-left: $grid-gutter-width;
          }
      }
  }

  .flexbox & {
      @media screen and (min-width: $screen-is) {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          li {
              flex: 0 1 auto;
              flex-basis: calc(50% - 15px);
          }
      }
  }
}

// .no-sidebar-primary {
//   .horizontal-list {
//       li {
//           @media screen and (min-width: $screen-md) {
//               float: left;
//               width: calc(33.33% - 20px);
//               margin-left: $grid-gutter-width;
//
//               &:nth-child(odd) {
//                   clear: none;
//               }
//
//               &:nth-child(3n + 1) {
//                   margin-left: 0;
//                   clear: left;
//               }
//           }
//       }
//   }
//
//   .flexbox & {
//       .horizontal-list {
//           li {
//               @media screen and (min-width: $screen-md) {
//                   flex: 0 1 auto;
//                   flex-basis: calc(33.33% - 20px);
//               }
//           }
//       }
//   }
// }

// Set pipes after each element except the last one
.pipe-after {
    &::after {
        padding: 0 .5em;
        content: '|';
        vertical-align: top;
    }
    &:last-child {
        &::after {
            display: none;
        }
    }
}

// Set pipes before each element except the first one
.pipe-before {
    &::before {
        padding: 0 .5em;
        content: '|';
        vertical-align: top;
    }
    &:first-child {
        &::before {
            display: none;
        }
    }
}

// Separator list item created by piped walker
.pipe-separator {
    span {
        display: block;
        padding: $nav-link-padding 0;
    }
}

// Horizontally justified list with no flexbox fallback
%justified-list {
    @include list-unstyled;
    margin: 0;
    padding: 0;
    font-size: 0;
    text-align: justify;
    text-justify: distribute-all-lines; /* distribute items in IE */
    vertical-align: middle;
    zoom: 1;


    &::after {
        display: inline-block;
        width: 100%;
        content: '';
    }

    li {
        text-align: center;

        a {
            display: block;
        }
    }

    > li {
        display: inline-block;
        position: relative;

    }

    .flexbox & {
        display: flex;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        &::after {
            display: none;
        }

        > li {
            display: flex;
            flex-direction: column;
            flex: 0 1 auto;
            flex-grow: 2;

            &.pipe-separator {
                flex-grow: 0;
            }
        }
    }
}

// Hide icon and show label in menu-items (by menu-icons plugin) (revert backend setting)
.icon-to-text {
    .right-off-canvas-menu &,
    .footer-menu & {
        i {
            display: none;
        }

        img._svg {
            display: none;
        }

        span.visuallyhidden {
            position: static;
            margin: auto;
            width: auto;
            height: auto;
            clip: auto;
            overflow: auto;
        }
    }
}
