#header {
    @extend .hidden-xs;
    @extend .hidden-is;
    width: 100%;
    font-size: $font-size-tiny;
    color: $color-primary;
    transition: 350ms all ease-in-out;
    z-index: 1200;

    &.affix {
        position: fixed;
        top: 0;

        .header-contact {
            position: fixed;
            top: -100%;
        }

        .header-content {
            padding: 1em 0;

            .header-nav {
                padding-top: 0;

                ul {
                    transform: translateY(.5em);
                }

                i {
                    @media screen and (min-width: $screen-md-min) {
                        font-size: 2em;
                    }
                }
            }

            .site-logo {
                @media screen and (min-width: $screen-md-min) {
                    img {
                        max-width: 140px;
                        max-height: 56px;
                    }
                }
            }
        }

        .opening-times-wrap {
            @media screen and (min-width: $screen-md-min) {
                .opening-times-inner-wrap {
                    top: -165%;
                }
                .opening-times-title,
                .opening-times-content {
                    visibility: hidden;
                }

                .site-search {
                    display: block;
                }
            }
        }
    }


    // First line of the website. Often contains contact information
    .header-contact {
        border-bottom: 1px solid rgba($color-primary, 0.25);
        padding: .33em 0;
        width: 100%;
        transition: 350ms all ease-in-out;

        .row {
            display: flex;
            align-items: center;
        }

        .topbar-site-search {
            @include make-sm-column(4);

            label {
                @media screen and (max-width: $screen-sm-max) {
                    max-width: 70%;
                }
            }
            .search-field {
                height: 16px;
                font-size: 12px;

                @media screen and (max-width: $screen-sm-max) {
                    max-width: 84%;
                }
            }

            .search-submit {
                padding: 0.325em;
                font-size: 12px;
            }
        }

        .contact-infos {
            @include make-sm-column(6);
            @include make-md-column(5);

            ul {

                li {
                    font-size: $font-size-tiny;

                    a,
                    i,
                    span {
                        display: inline-block;
                    }

                    .tel-label,
                    .email-label {
                        font-weight: 600;
                    }
                }
            }
        }

        .lang-switch {
            @include make-sm-column(2);
            @include make-md-column(3);
            text-align: right;

            .wpml-ls {
                padding: 0;

                ul {
                    white-space: nowrap;

                    li {
                        margin: 0;
                        padding: 0;

                        a {
                            margin: 0 0 0 1em;
                            padding: 0;
                        }
                    }
                }

                .wpml-ls-native {
                    @media screen and (max-width: $screen-sm-max) {
                        display: none;
                    }
                }
            }
        }
    }

    // Opening times styles. Theme or shortcode content
    .opening-times-wrap {
        @include make-sm-column(5);
        @include make-md-column(4);
        position: static;

        .opening-times-inner-wrap {
            position: absolute;
            height: 100%;
        }

        .site-search {
            display: none;
        }
    }
    .opening-times-title {
        transition: all 250ms ease-in-out;
    }
    .opening-times-content {
        transition: all 250ms ease-in-out;
        white-space: nowrap;

        table {
            line-height: 1.3;

            tr {
                &:first-child {
                    height: 1.8em;
                    vertical-align: top;
                }
            }
        }
    }

    // Wrapper for site logo and header widgets
    .header-content {
        padding: 1.25em 0 1.75em;
        background: #fff;
        transition: 350ms all ease-in-out;

        .site-logo {
            @include make-sm-column(4);

            img {
                transition: 350ms all ease-in-out;
            }

            .logo-link {
                display: block;
                height: 100px;
                background-color: $brand-primary;
            }
        }

        .header-nav {
            @include make-sm-column(3);
            @include make-md-column(4);

            @media screen and (min-width: $screen-md-min) {
                padding-top: 2.5em;
            }

            ul {
                li {
                    font-size: $font-size-tiny;

                    @media screen and (max-width: $screen-sm-max) {
                        display: block;
                        margin: 0 0 .5em;
                        float: none;
                        font-size: $font-size-small;

                        &:last-child {
                            margin: 0;
                        }
                    }

                    a {
                        &:hover {
                            text-decoration: none;
                        }

                        span {
                            color: $text-color;
                        }
                    }

                    @media screen and (min-width: $screen-md-min) {

                        i {
                            display: block;
                            margin-top: 0;
                            margin-right: 0;
                            font-size: 2.5em;
                            text-align: center !important;
                        }

                        .cart-count,
                        .wishlist-count {
                            position: absolute;
                            top: -0.8em;
                            right: .5em;
                        }
                    }
                }
            }
        }
    }
}
.shop-pages-infobanner {
    padding: .5em 0;
    left: 0;
    right: 0;
    background: rgba($color-primary, 0.7);
    color: #fff;
    font-size: $font-size-small;
    text-align: center;

    p {
        margin: 0;
    }

    .topbar & {
        position: absolute;
        top: 55px;
        padding: .4em 0;
        background: rgba($color-primary, 0.9);
        font-size: $font-size-tiny;
        line-height: 1.45;
    }
}
