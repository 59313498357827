.woocommerce {
    .button,
    button.button.alt,
    a.button,
    a.button.alt,
    input.button,
    input.button.alt  {
        @extend %btn;
        @extend %cta-btn;
        @extend %cta-btn-hover;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}
