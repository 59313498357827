.woocommerce-cart,
.woocommerce-checkout,
.woocommerce-wishlist,
.woocommerce-account {
    .content-wrap {
        @media screen and (max-width: $screen-is) {
            padding-top: 1.25em;
        }
    }
}

.woocommerce {
    .quantity .qty {
        min-width: 4.5em;
    }
    .woocommerce-info {
        border-color: $color-primary;

        &::before {
            color: $color-primary;
        }
    }

    .woocommerce-message {
        @media screen and (max-width: $screen-is) {
            font-size: $font-size-small;

            .button {
                display: block;
                float: none;
                margin-bottom: 1em;
                text-align: center;
            }
        }
    }
}
.woocommerce span.onsale,
span.onsale {
    display: inline-block;
    position: absolute;
    top: 1em;
    right: 1em;
    margin: 0;
    padding: 0;
    width: 6.5em;
    height: 6.5em;
    min-width: none;
    min-height: none;
    background-color: $color-primary;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    line-height: 6.5em;
    overflow: hidden;
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;

    .single-product & {
        left: 1em;
        right: auto;
    }
}
