/****************************************************
*************** Scroll To-Top Button ****************
****************************************************/
.to-top {
    position: fixed;
    bottom: 1em;
    right: 1em;
    z-index: 100;
    border: 1px solid #fff;
    padding: .33em .75em;
    background: $color-primary;
    color: #fff;
    opacity: 1;
    mix-blend-mode: luminosity;
    transition: opacity $offcanvas-duration ease-in-out;

    .move-left &, .move-right & {
        opacity: 0;
    }
    &:hover{
        background: lighten($color-primary,10%);
        color:#FFF;
    }
}




/****************************************************
********************* CTA Buttons *******************
****************************************************/
%btn {
    display: inline-block;
    padding: 8px 12px 8px 12px;
    border-color: transparent;
    border-radius: 3px;
    font-family: $font-family-serif;
    font-size: $font-size-base;
    font-weight: 400;
    transition: 250ms all ease-in-out;
    vertical-align: middle;
}

%cta-btn {
    background: $color-primary;
    color: #FFF;
    text-transform: uppercase;
}

%cta-btn-hover {
    &:hover,
    &:active,
    &:focus {
        background: darken($color-primary,10%) !important;
        color: #FFF !important;
    }
}


// Theme buttons, cf-form buttons, slideshow buttons
a.btn,
a.cta-btn,
a.caldera-forms-modal,
input[type="submit"].btn, {
    @extend %btn;
    @extend %cta-btn;
    @extend %cta-btn-hover;
}

// bb-plugin buttons
.fl-module-content .fl-button-wrap a.fl-button {
    @extend %btn;

    * {
        color: inherit;
    }

    .cta-btn & {
        @extend %cta-btn;
        @extend %cta-btn-hover;
    }
}

// uabb-addon buttons
.fl-module-content .uabb-button-wrap a.uabb-button {
    @extend %btn;

    .cta-btn & {
        @extend %cta-btn;
        @extend %cta-btn-hover;
    }
}



/****************************************************
******************* Floaty Buttons ******************
****************************************************/
.floaty-wrap {
    position: fixed;
    left: 0;
    top: 40%;
    z-index: 100;

    .floaty_buttons {
        margin: 0;
        padding: 0;
        list-style: none;

        .menu-item > a{
            display: block;
            padding: 1.2em;
            padding-left: 2.4em;
            background: $navbar-default-bg;
            color: $navbar-default-link-color;
            transition: 250ms all ease-in-out;
            transform: translateX(-1.2em);

            &:hover {
                background: $navbar-default-link-hover-bg;
                color: $navbar-default-link-hover-color;
                transform: translateX(0);
                -ms-transform: translateX(0);
            }
        }
    }
}
