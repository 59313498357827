.fl-module-blog-posts {
    .fl-module-content {
        .slick-arrow {
            i {
                font-size: 60px;
                transition: all 250ms ease-in-out;

                &:hover {
                    color: $color-tertiary;
                }
            }
        }
    }

    &.news-carousel {

        .location {
            font-family: $font-family-serif;
            color: $color-primary;
        }
        .uabb-blog-post-content {
            @media screen and (max-width: $screen-xs-max) {
                padding-bottom: 1em;
            }
        }

        @media screen and (max-width: $screen-is-max){
            .uabb-post-thumbnail {
                img {
                    width: 100%;
                }
            }
        }

        @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
            .uabb-post-heading {
                font-size: $font-size-base;
            }

            .uabb-post-thumbnail {
                max-width: 250px;
                float: left;
            }

            .uabb-blog-post-content {
                padding: $grid-gutter-width/2;
                padding-bottom: 0;
                width: calc(100% - 250px);
                float: left;
                font-size: $font-size-small;

                .uabb-blog-posts-description {
                    padding-top: .5em;
                }
            }
        }
    }

    &.woocommerce-carousel {
        .uabb-post-heading {
            padding: .75em 0 0;
            color: darken($color-primary, 10%);
            font-size: $font-size-base*1.1;
            line-height: 1.2;

            @media screen and (min-width: $screen-is-min) {
                min-height: 5.75em;
            }

            @media screen and (min-width: 530px) {
                min-height: 4.5em;
            }
        }

        .uabb-blog-post-inner-wrap {
            .product-cat {
                display: block;
                border-bottom: 2px dotted $gray-light;
                margin-bottom: .5em;
                padding-bottom: .5em;
                color: $gray-light;
                font-family: $font-family-serif;
                font-size: $font-size-small;
                line-height: 1.25;
                text-transform: uppercase;
                min-height: 3em;
            }


            .price,
            .price-wrap {
                display: block;
                margin: 0 0 .25em;
                color: $text-color;
                font-family: $font-family-serif;
                font-size: $font-size-big;
            }

            .unit-wrap,
            .unit {
                font-size: $font-size-tiny !important;
            }

            .price-unit {
                font-size: $font-size-tiny;
                line-height: 1;

                ins,
                del {
                    margin: 0 !important;
                    padding: 0 !important;
                    font-size: $font-size-tiny;
                }
            }

            .wc-gzd-additional-info {
                display: block;
                margin: 0;
            }

            // Price Styles for products on sale
            &.sale {
                .price,
                .price-wrap {

                    @media screen and (max-width: $screen-xs-max){
                        font-size: $font-size-base;
                    }

                    ins {
                        display: inline-block;
                        text-align: left;
                        text-decoration: none;
                        font-weight: bold;
                    }
                    del {
                        display: inline-block;
                        margin-right: .5em;
                        padding-right: 1em;
                        border-right: 1px solid $color-secondary;
                        color: $color-primary;
                        font-size: $font-size-base;
                        opacity: .5;
                        text-align: right;

                        @media screen and (max-width: $screen-xs-max){
                            margin-right: .25em;
                            padding-right: .5em;
                        }
                    }

                }

                .price-unit {
                    del {
                        padding-right: .66em !important;
                    }
                    ins {
                        padding-left: .25em !important;
                        font-weight: bold;
                    }
                }
            }

            .uabb-read-more-text {
                padding-top: 1em;
                color: $color-primary;
                font-size: $font-size-small;

                a {
                    color: $color-primary;
                    text-decoration: underline;
                    text-transform: uppercase;
                }

                .uabb-next-right-arrow {
                    display: none;
                }
            }
        }

        .slick-arrow {
            i {
                font-size: 60px;
                transition: all 250ms ease-in-out;
                background: none;
                border: none;

                &:hover {
                    color: $color-secondary;
                }
            }
        }
    }
}
