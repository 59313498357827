/****************************************************
*************** General Footer Styles ***************
****************************************************/
#footer {
    padding-top: .5em;
    background: $gray-dark;
    color: #fff;
}




/****************************************************
******************** Footer Menu ********************
****************************************************/
.footer-menu {
    @extend .hidden-xs;
    @extend .hidden-is;
    padding: $nav-link-padding 0;
    background: rgba(255, 255, 255, 0.25);

    nav {
        @extend .container;

        #footer-nav {
            align-items: flex-start;
            font-size: 0px;

            @media screen and (min-width: $screen-md-min){
                font-size: 0px;
            }

            // Generic #footer-nav list-item styles
            li {
                flex: 0 1 auto;
                text-align: left;
                vertical-align: top;
                font-size: inherit;

                &.current-menu-item > a {
                    font-weight: bold;
                    color: $color-primary;
                }

                a {
                    color: $desktop-nav-color;
                    font-size: $font-size-small;

                    @media screen and (min-width: $screen-md-min){
                        font-size: $font-size-base;
                    }

                    &:hover {
                        color: $color-primary;
                        text-decoration: none;
                    }
                }
            }

            > li {
                // First Level Sub-Menus
                > .sub-menu {
                    .menu-item a {

                    }
                }
            }
        }
    }
}




/****************************************************
************ Footer Widget Area Styles **************
****************************************************/
.footer-social-links {
    @extend .container;
    padding: $grid-gutter-width/2 0;
    text-align: center;

    h4 {
        margin: 0 0 $grid-gutter-width/2;
    }

    .social-links-list {
        @extend .nav;
        @extend .nav-center;
    }
}




/****************************************************
************ Footer Widget Area Styles **************
****************************************************/
.footer-widgets {
    @extend .container;
    padding-top: 4em;
    padding-bottom: 2em;
    font-size: $font-size-small;

    @media screen and (max-width: $screen-xs-max) {
        text-align: center;

        .opening-times-content {
            table {
                margin: 0 auto;
            }
        }

        table {
            td {
                display: block;
            }
        }
    }

    a {
        color: #fff;
    }


    .footer-widgets-left {
        @include make-sm-column(3);

        @media screen and (min-width: $screen-sm-min) {
            border-right: 1px solid #fff;
        }
    }
    .footer-widgets-right {
        @include make-sm-column(9);
    }
    .footer-logo {
        margin-bottom: 2em;
        max-width: 200px !important;
    }
}




/****************************************************
******** Copyright Line (Last Line of site) *********
****************************************************/
.copyright {
    padding-bottom: 2.5em;

    .cookies-not-accepted & {
        padding-bottom: 5em;

        @media screen and (min-width: $screen-sm-min){
            padding-bottom: 3em
        }
    }
}

.copyright-row {
    @extend .row;
    border-top: 1px solid #fff;
    padding-top: $grid-gutter-width/2;
    font-size: $font-size-tiny;


    // Unternehmensname, Copyright, Adresse etc.
    .copyright-info {
        @extend .nav;
        @include make-sm-column(7);
        padding-bottom: $grid-gutter-width/2;
        text-align: center;

        @media screen and (min-width: $screen-sm-min){
            text-align: left;
        }

        li {
            @extend .pipe-after;
            margin: 0;
            font-size: inherit;

            &::after {
                display: none;
            }
            @media screen and (min-width: $screen-sm-min){
                float: left;

                &::after {
                    display: inline-block;;
                }
            }
        }
    }

    // Copyright Navigation (Kontakt, Impressum, Datenschutz etc.)
    .copyright-navigation {
        @include make-sm-column(5);
        padding-bottom: $grid-gutter-width/2;

        .menu {
            @include list-unstyled;
            @media screen and (min-width: $screen-sm-min){
                float: right;
            }
            .menu-item {
                float: none;
                display: block;
                text-align: center;
                @extend .pipe-after;

                a {
                    color: $desktop-nav-color;
                }

                &::after {
                    display: none;
                }

                @media screen and (min-width: $screen-sm-min){
                    float: left;

                    &::after {
                        display: inline-block;;
                    }
                }
            }
        }
    }
}
