@font-face {
  font-family: 'fontawesome-custom';
  src:  url('#{$icomoon-font-path}/fontawesome-custom.eot?exz5aj');
  src:  url('#{$icomoon-font-path}/fontawesome-custom.eot?exz5aj#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/fontawesome-custom.ttf?exz5aj') format('truetype'),
    url('#{$icomoon-font-path}/fontawesome-custom.woff?exz5aj') format('woff'),
    url('#{$icomoon-font-path}/fontawesome-custom.svg?exz5aj#fontawesome-custom') format('svg');
  font-weight: normal;
  font-style: normal;
}

i[class*='custom-fa'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fontawesome-custom' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-fa-plus {
  &:before {
    content: $custom-fa-plus;
  }
}
.custom-fa-minus {
  &:before {
    content: $custom-fa-minus;
  }
}
.custom-fa-search {
  &:before {
    content: $custom-fa-search;
  }
}
.custom-fa-envelope-o {
  &:before {
    content: $custom-fa-envelope-o;
  }
}
.custom-fa-check {
  &:before {
    content: $custom-fa-check;
  }
}
.custom-fa-home {
  &:before {
    content: $custom-fa-home;
  }
}
.custom-fa-clock-o {
  &:before {
    content: $custom-fa-clock-o;
  }
}
.custom-fa-download {
  &:before {
    content: $custom-fa-download;
  }
}
.custom-fa-map-marker {
  &:before {
    content: $custom-fa-map-marker;
  }
}
.custom-fa-chevron-left {
  &:before {
    content: $custom-fa-chevron-left;
  }
}
.custom-fa-chevron-right {
  &:before {
    content: $custom-fa-chevron-right;
  }
}
.custom-fa-gift {
  &:before {
    content: $custom-fa-gift;
  }
}
.custom-fa-comment {
  &:before {
    content: $custom-fa-comment;
  }
}
.custom-fa-chevron-up {
  &:before {
    content: $custom-fa-chevron-up;
  }
}
.custom-fa-chevron-down {
  &:before {
    content: $custom-fa-chevron-down;
  }
}
.custom-fa-shopping-cart {
  &:before {
    content: $custom-fa-shopping-cart;
  }
}
.custom-fa-twitter-square {
  &:before {
    content: $custom-fa-twitter-square;
  }
}
.custom-fa-facebook-square {
  &:before {
    content: $custom-fa-facebook-square;
  }
}
.custom-fa-comments {
  &:before {
    content: $custom-fa-comments;
  }
}
.custom-fa-thumbs-o-up {
  &:before {
    content: $custom-fa-thumbs-o-up;
  }
}
.custom-fa-thumbs-o-down {
  &:before {
    content: $custom-fa-thumbs-o-down;
  }
}
.custom-fa-linkedin-square {
  &:before {
    content: $custom-fa-linkedin-square;
  }
}
.custom-fa-phone {
  &:before {
    content: $custom-fa-phone;
  }
}
.custom-fa-phone-square {
  &:before {
    content: $custom-fa-phone-square;
  }
}
.custom-fa-twitter {
  &:before {
    content: $custom-fa-twitter;
  }
}
.custom-fa-facebook {
  &:before {
    content: $custom-fa-facebook;
  }
}
.custom-fa-facebook-f {
  &:before {
    content: $custom-fa-facebook-f;
  }
}
.custom-fa-credit-card {
  &:before {
    content: $custom-fa-credit-card;
  }
}
.custom-fa-feed {
  &:before {
    content: $custom-fa-feed;
  }
}
.custom-fa-rss {
  &:before {
    content: $custom-fa-rss;
  }
}
.custom-fa-filter {
  &:before {
    content: $custom-fa-filter;
  }
}
.custom-fa-table {
  &:before {
    content: $custom-fa-table;
  }
}
.custom-fa-truck {
  &:before {
    content: $custom-fa-truck;
  }
}
.custom-fa-pinterest {
  &:before {
    content: $custom-fa-pinterest;
  }
}
.custom-fa-pinterest-square {
  &:before {
    content: $custom-fa-pinterest-square;
  }
}
.custom-fa-google-plus-square {
  &:before {
    content: $custom-fa-google-plus-square;
  }
}
.custom-fa-google-plus {
  &:before {
    content: $custom-fa-google-plus;
  }
}
.custom-fa-caret-down {
  &:before {
    content: $custom-fa-caret-down;
  }
}
.custom-fa-caret-up {
  &:before {
    content: $custom-fa-caret-up;
  }
}
.custom-fa-caret-left {
  &:before {
    content: $custom-fa-caret-left;
  }
}
.custom-fa-caret-right {
  &:before {
    content: $custom-fa-caret-right;
  }
}
.custom-fa-envelope {
  &:before {
    content: $custom-fa-envelope;
  }
}
.custom-fa-linkedin {
  &:before {
    content: $custom-fa-linkedin;
  }
}
.custom-fa-comment-o {
  &:before {
    content: $custom-fa-comment-o;
  }
}
.custom-fa-comments-o {
  &:before {
    content: $custom-fa-comments-o;
  }
}
.custom-fa-coffee {
  &:before {
    content: $custom-fa-coffee;
  }
}
.custom-fa-cutlery {
  &:before {
    content: $custom-fa-cutlery;
  }
}
.custom-fa-angle-left {
  &:before {
    content: $custom-fa-angle-left;
  }
}
.custom-fa-angle-right {
  &:before {
    content: $custom-fa-angle-right;
  }
}
.custom-fa-angle-up {
  &:before {
    content: $custom-fa-angle-up;
  }
}
.custom-fa-angle-down {
  &:before {
    content: $custom-fa-angle-down;
  }
}
.custom-fa-quote-left {
  &:before {
    content: $custom-fa-quote-left;
  }
}
.custom-fa-quote-right {
  &:before {
    content: $custom-fa-quote-right;
  }
}
.custom-fa-calendar-o {
  &:before {
    content: $custom-fa-calendar-o;
  }
}
.custom-fa-rss-square {
  &:before {
    content: $custom-fa-rss-square;
  }
}
.custom-fa-thumbs-up {
  &:before {
    content: $custom-fa-thumbs-up;
  }
}
.custom-fa-thumbs-down {
  &:before {
    content: $custom-fa-thumbs-down;
  }
}
.custom-fa-youtube-square {
  &:before {
    content: $custom-fa-youtube-square;
  }
}
.custom-fa-youtube {
  &:before {
    content: $custom-fa-youtube;
  }
}
.custom-fa-xing {
  &:before {
    content: $custom-fa-xing;
  }
}
.custom-fa-xing-square {
  &:before {
    content: $custom-fa-xing-square;
  }
}
.custom-fa-youtube-play {
  &:before {
    content: $custom-fa-youtube-play;
  }
}
.custom-fa-instagram {
  &:before {
    content: $custom-fa-instagram;
  }
}
.custom-fa-tumblr {
  &:before {
    content: $custom-fa-tumblr;
  }
}
.custom-fa-tumblr-square {
  &:before {
    content: $custom-fa-tumblr-square;
  }
}
.custom-fa-envelope-square {
  &:before {
    content: $custom-fa-envelope-square;
  }
}
.custom-fa-google {
  &:before {
    content: $custom-fa-google;
  }
}
.custom-fa-automobile {
  &:before {
    content: $custom-fa-automobile;
  }
}
.custom-fa-car {
  &:before {
    content: $custom-fa-car;
  }
}
.custom-fa-history {
  &:before {
    content: $custom-fa-history;
  }
}
.custom-fa-yelp {
  &:before {
    content: $custom-fa-yelp;
  }
}
.custom-fa-wifi {
  &:before {
    content: $custom-fa-wifi;
  }
}
.custom-fa-paypal {
  &:before {
    content: $custom-fa-paypal;
  }
}
.custom-fa-cc-visa {
  &:before {
    content: $custom-fa-cc-visa;
  }
}
.custom-fa-cc-amex {
  &:before {
    content: $custom-fa-cc-amex;
  }
}
.custom-fa-cc-paypal {
  &:before {
    content: $custom-fa-cc-paypal;
  }
}
.custom-fa-cc-stripe {
  &:before {
    content: $custom-fa-cc-stripe;
  }
}
.custom-fa-cart-plus {
  &:before {
    content: $custom-fa-cart-plus;
  }
}
.custom-fa-cart-arrow-down {
  &:before {
    content: $custom-fa-cart-arrow-down;
  }
}
.custom-fa-facebook-official {
  &:before {
    content: $custom-fa-facebook-official;
  }
}
.custom-fa-pinterest-p {
  &:before {
    content: $custom-fa-pinterest-p;
  }
}
.custom-fa-whatsapp {
  &:before {
    content: $custom-fa-whatsapp;
  }
}
.custom-fa-bed {
  &:before {
    content: $custom-fa-bed;
  }
}
.custom-fa-hotel {
  &:before {
    content: $custom-fa-hotel;
  }
}
.custom-fa-tripadvisor {
  &:before {
    content: $custom-fa-tripadvisor;
  }
}
.custom-fa-amazon {
  &:before {
    content: $custom-fa-amazon;
  }
}
.custom-fa-map-pin {
  &:before {
    content: $custom-fa-map-pin;
  }
}
.custom-fa-map-signs {
  &:before {
    content: $custom-fa-map-signs;
  }
}
.custom-fa-map-o {
  &:before {
    content: $custom-fa-map-o;
  }
}
.custom-fa-map {
  &:before {
    content: $custom-fa-map;
  }
}
.custom-fa-commenting {
  &:before {
    content: $custom-fa-commenting;
  }
}
.custom-fa-commenting-o {
  &:before {
    content: $custom-fa-commenting-o;
  }
}
.custom-fa-product-hunt {
  &:before {
    content: $custom-fa-product-hunt;
  }
}
.custom-fa-volume-control-phone {
  &:before {
    content: $custom-fa-volume-control-phone;
  }
}
.custom-fa-snapchat {
  &:before {
    content: $custom-fa-snapchat;
  }
}
.custom-fa-snapchat-ghost {
  &:before {
    content: $custom-fa-snapchat-ghost;
  }
}
.custom-fa-snapchat-square {
  &:before {
    content: $custom-fa-snapchat-square;
  }
}
.custom-fa-google-plus-circle {
  &:before {
    content: $custom-fa-google-plus-circle;
  }
}
.custom-fa-google-plus-official {
  &:before {
    content: $custom-fa-google-plus-official;
  }
}
.custom-fa-envelope-open {
  &:before {
    content: $custom-fa-envelope-open;
  }
}
.custom-fa-envelope-open-o {
  &:before {
    content: $custom-fa-envelope-open-o;
  }
}
.custom-fa-shower {
  &:before {
    content: $custom-fa-shower;
  }
}
.custom-fa-bath {
  &:before {
    content: $custom-fa-bath;
  }
}
.custom-fa-bathtub {
  &:before {
    content: $custom-fa-bathtub;
  }
}
.custom-fa-s15 {
  &:before {
    content: $custom-fa-s15;
  }
}
.custom-fa-snowflake-o {
  &:before {
    content: $custom-fa-snowflake-o;
  }
}
