.woocommerce-cart {
    // Start bugfix for cart content strange flickering after recalculation shipping cost
    @media screen and (max-width: $screen-is-max) {
        height: auto;

        .topbar {
            position: static;
        }

        .page-wrap {
            padding: 0;
        }

        .right-off-canvas-menu {
            margin-top: -55px;
        }
    }
    // End bugfix

    table {
        &.cart {
            tr {
                &:nth-child(even) {
                    background: $color-tertiary;
                }
            }
            th,
            td {
                &.product-quantity {
                    .quantity {
                        @include clearfix();
                    }
                    .qty {
                        @media screen and (max-width: $screen-sm-max) {
                            float: right;
                        }
                    }
                }

                // Coupopn code areas
                &.actions {
                    .coupon {
                        display: block;
                        width: 100%;

                        .input-text {
                            min-width: 150px;
                        }

                        .input-text,
                        .button {
                            margin-bottom: .5em;

                            @media screen and (max-width: $screen-xs-max) {
                                width: 100%;
                            }

                            @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                                width: 48%;
                            }
                        }

                        .button {
                            @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                                float: right;
                            }
                        }
                    }
                }
            }
        }
    }

    // Summary of cart totals
    .cart-collaterals {
        .cart_totals {

            @media screen and (min-width: $screen-sm-min) {
                width: auto;
                max-width: 60%;
                float: right;
            }

            table {
                tr {
                    &:nth-child(even) {
                        background: $color-tertiary;
                    }
                }
            }
        }
    }

    .wc-proceed-to-checkout {
        text-align: right;
    }
}
