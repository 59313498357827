.fl-module-uabb-woo-products {
    .uabb-woo-products-carousel {
        li.product {
            .uabb-flash-container {
                top: 0;
                right: 0;
                left: auto;
                margin: 0;

                .uabb-onsale {
                    font-size: 10px;
                    text-decoration: underline;
                    text-transform: uppercase;
                }
            }

            .woocommerce-loop-product__title {
                padding: 0;
                margin: 0;
                color: darken($color-primary, 10%);
                font-size: $font-size-base*1.1;
                line-height: 1.2;
    
                @media screen and (min-width: $screen-is-min) {
                    min-height: 5.75em;
                }
    
                @media screen and (min-width: 530px) {
                    min-height: 4.5em;
                }
            }
    
            .uabb-woo-products-summary-wrap {
                .uabb-woo-product-category {
                    display: block;
                    border-bottom: 2px dotted $gray-light;
                    color: $gray-light;
                    font-family: $font-family-serif;
                    font-size: $font-size-small;
                    line-height: 1.25;
                    text-transform: uppercase;
                    min-height: 3em;
                }
    
    
                .price,
                .price-wrap {
                    display: block;
                    margin: 0 0 .25em;
                    color: $text-color;
                    font-family: $font-family-serif;
                    font-size: $font-size-big;
                }
    
                .unit-wrap,
                .unit {
                    font-size: $font-size-tiny !important;
                }
    
                .price-unit {
                    font-size: $font-size-tiny;
                    line-height: 1;
    
                    ins,
                    del {
                        margin: 0 !important;
                        padding: 0 !important;
                        font-size: $font-size-tiny;
                    }
                }
    
                .wc-gzd-additional-info {
                    display: block;
                    margin: 0;
                }
    
                .show-product-link {
                    padding-top: 1em;
                    color: $color-primary;
                    font-size: $font-size-small;
                    color: $color-primary;
                    text-decoration: underline;
                    text-transform: uppercase;
                }
            }

            // Price Styles for products on sale
            &.sale {
                .price,
                .price-wrap {

                    @media screen and (max-width: $screen-xs-max){
                        font-size: $font-size-base;
                    }

                    ins {
                        display: inline-block;
                        text-align: left;
                        text-decoration: none;
                        font-weight: bold;
                    }
                    del {
                        display: inline-block;
                        margin-right: .5em;
                        padding-right: 1em;
                        border-right: 1px solid $color-secondary;
                        color: $color-primary;
                        font-size: $font-size-base;
                        opacity: .5;
                        text-align: right;

                        @media screen and (max-width: $screen-xs-max){
                            margin-right: .25em;
                            padding-right: .5em;
                        }
                    }

                }

                .price-unit {
                    del {
                        padding-right: .66em !important;
                    }
                    ins {
                        padding-left: .25em !important;
                        font-weight: bold;
                    }
                }
            }
        }

        .slick-arrow {
            &.slick-prev{
                left: -30px;
            }

            &.slick-next{
                right: -30px;
            }

            @media screen and (min-width: $screen-sm-min) {
                &.slick-prev{
                    left: -50px;
                }
    
                &.slick-next{
                    right: -50px;
                }
            }

            i {
                font-size: 60px;
                transition: all 250ms ease-in-out;
                background: none;
                border: none;

                &:hover {
                    color: $color-secondary;
                }
            }
        }
    }
}
