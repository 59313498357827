@media screen and (min-width: $screen-sm-min) {

    .fl-module-cta {
        .fl-cta-title {
            font-weight: 600;
            padding: 0;
        }
        .fl-cta-inline {
            .fl-cta-text {
                width: 60%;
            }
            .fl-cta-button {
                width: 40%;

                a.fl-button {
                    padding: 16px;
                    max-width: 260px;
                }
            }
        }
    }
}
