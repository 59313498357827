.nav,
.nav ul {
    @include list-unstyled();

    li {
        position: relative;
        font-size: $font-size-base;

        a {
            display: block;
        }

        // Menu item description
        &.has-description {
            .menu-item-description {
                display: inline-block;
                margin-right: .25em;
                width: 1em;
                vertical-align: middle;
                line-height: 1;

                img {
                    display: inline-block;
                    width: auto;
                    height: auto;
                    vertical-align: initial;
                }

                i {
                    margin-top: -.265em;
                }
            }
        }

        // Hide menu-item label if icon and hide-text class is set to menu-item
        &.hide-text {
            .menu-item-description {
                    margin: 0;
            }

            .item-title {
                @extend .sr-only;
            }
        }
    }

    &.nav-center {
        display: block;
        margin: 0 auto;
        text-align: center;

        li {
            display: inline-block;
            margin: 0 1em;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &.nav-left {
        float: left;

        li {
            float: left;
            margin-right: 1em;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &.nav-right {
        float: right;

        li {
            float: left;
            margin-left: 1em;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    &.nav-horizontal-dropdown {
        position: relative;

        > li {
            position: static !important;

            > .sub-menu {
                position: absolute;

                li {
                    display: inline-block;

                    @media screen and (max-width: $screen-sm-max) {

                        a {
                            font-size: $font-size-tiny !important;
                            padding: 1em 0.75em !important;
                        }
                    }
                }
            }
        }
    }
}

.nav-justified {
    @extend %justified-list;
}
