.fl-callout {
    // Extend the css for specific layout types with more specific selectors. E.g.:
    // &.fl-callout-center {}
    // &.fl-callout-has-photo {}
    // &.fl-callout-has-icon {}

    // Generic layout selectors
    .fl-callout-content {

        .fl-callout-photo {}

        .fl-icon {}

        .fl-callout-title {}

        .fl-callout-text-wrap{
            // CTA with button style
            .fl-button-wrap {
                .fl-button {
                    .fl-button-text {}
                }
            }
            // CTA with text style
            .fl-callout-cta-link {}
        }
    }

    .teaserbox & {
        .fl-callout-content {
            background: #fff;
            box-shadow: 0 0 6px 1px rgba(0,0,0,0.1);
            font-size: $font-size-small;

            .fl-photo-content {
                display: block;
                
                img {
                    width: 100%;
                    max-width: none;
                }
            }

            .fl-callout-title {
                display: none;
            }

            .fl-callout-text-wrap {
                .fl-callout-text {
                    padding: 1em;
                    font-weight: 600;
                }

                .fl-callout-cta-link {
                    display: block;
                    border-top: 2px dotted $color-primary;
                    padding: .75em;
                    font-family: $font-family-serif;
                    font-size: 1.3em;

                    &::after {
                        display: inline-block;
                        margin-left: .5em;
                        content: '\f0da';
                        font-family: 'fontawesome-custom';
                        font-size: .9em;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}
