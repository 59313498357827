.woocommerce.archive,
.single-product {
    #content,
    #main {
        .product-header-wrap {
            @include make-row();
            margin-bottom: 2em;

            .woocommerce-products-header__title {
                font-size: 36px;
            }

            .woocommerce-products-header,
            .woocommerce-ordering {
                word-break: break-word;
                float: left;

                select {
                    margin-top: 18px;

                    @media screen and (min-width: $screen-sm-min) {
                        max-width: 300px;
                        float: right;
                    }
                    @media screen and (min-width: $screen-md-min) {
                        margin-top: 28px;
                    }
                }
                @include make-is-column(6);
            }
        }

        .product-categories-slider {
                @include clearfix();
                margin-bottom: 3em;

                .slick-arrow {
                    position: absolute;
                    top: 40%;
                    z-index: 1100;
                    color: rgba($gray-light, 0.5);
                    cursor: pointer;
                    font-size: 36px;
                    transition: all 250ms ease-in-out;
                    transform: translateY(-50%);
                    -ms-transform: translateY(-50%);

                    &:hover {
                        color: $gray-light;
                    }

                    &.arrow-prev {
                        left: 0;
                    }
                    &.arrow-next {
                        right: 0;
                    }
                }
        }

        ul.products {
            @include make-row();

            li.product {
                @include make-xs-column(12);
                @include make-is-column(6);
                @include make-md-column(4);
                position: relative;
                margin: 0;
                margin-bottom: 3.5em;
                border: 1px solid transparent;
                clear: none;
                float: left !important;
                font-size: $font-size-small;
                text-align: center;
                transition: all 250ms ease-in-out;

                @media screen and (max-width: $screen-xs-max) {
                    margin-bottom: 5em;
                }

                @media screen and (min-width: $screen-is-min) and (max-width: $screen-sm-max){
                    &.type-product {
                        &:nth-of-type(2n+1) {
                            clear: left !important;
                        }
                    }
                }

                @media screen and (min-width: $screen-md-min) {

                    &.type-product {
                        &:nth-of-type(3n+1) {
                            clear: left !important;
                        }
                        &:hover {
                            background: #fcfcfc;
                        }
                    }
                }

                &.product-category {
                    @include make-xs-column(6);
                    @include make-is-column(4);
                    margin-bottom: 0;
                    font-size: $font-size-small;

                    a {
                        display: block;

                        img {
                            margin: 0;
                        }
                    }
                }


                .wp-post-image {
                    margin: 0 auto;
                    padding-left: $grid-gutter-width/2;
                    padding-right: $grid-gutter-width/2;
                    width: auto;
                    max-height: 300px;
                }


                &.first {
                    clear: none;
                }
                .woocommerce-LoopProduct-wrapper {
                    position: relative;
                    padding: .75em 0 .33em;
                }

                a.button {
                    display: inline-block;
                    margin: 0 auto;
                }

                .product-cat {
                    display: block;
                    border-bottom: 2px dotted $gray-light;
                    padding: .5em 0;
                    margin-bottom: .25em;
                    color: $gray-light;
                    font-family: $font-family-serif;
                    font-size: $font-size-small;
                    line-height: 1.25;
                    text-transform: uppercase;

                    @media screen and (min-width: $screen-is-min) {
                        min-height: 3.66em;
                    }
                }

                .woocommerce-loop-product__title {
                    padding: .75em 0 0;
                    color: darken($color-primary, 10%);
                    font-size: $font-size-base*1.1;
                    line-height: 1.2;

                    @media screen and (min-width: $screen-is-min) {
                        min-height: 5.75em;
                    }

                    @media screen and (min-width: 530px) {
                        min-height: 4.5em;
                    }
                }

                .wc-gzd-additional-info {
                    display: block;
                    margin: .25em 0;
                }

                .price {
                    display: block;
                    margin: 0 0 .25em;
                    color: $text-color;
                    font-family: $font-family-serif;
                    font-size: $font-size-big;
                }

                .price-unit {
                    font-size: $font-size-tiny;
                    line-height: 1;

                    ins,
                    del {
                        margin: 0 !important;
                        padding: 0 !important;
                        font-size: $font-size-tiny;
                    }
                }

                .star-rating {
                    margin: 0 auto .5em;
                }

                // Price Styles for products on sale
                &.sale {
                    .price {

                        ins {
                            display: inline-block;
                            text-align: left;
                            text-decoration: none;
                        }
                        del {
                            display: inline-block;
                            margin-right: .5em;
                            padding-right: 1em;
                            border-right: 1px solid $color-secondary;
                            color: $color-primary;
                            font-size: $font-size-base;
                            opacity: .5;
                            text-align: right;
                        }
                    }

                    .price-unit {
                        del {
                            padding-right: .66em !important;
                        }
                        ins {
                            padding-left: .25em !important;
                        }
                    }
                }

                .product-link,
                .add_to_cart_button,
                .ajax_add_to_cart,
                .product_type_simple,
                .product_type_variable  {
                    display: inline-block;
                    border: 1px solid $color-primary;
                    border-radius: 2px;
                    margin: 0;
                    padding: .25em .5em;
                    background: none;
                    color: $color-primary;
                    font-family: $font-family-sans-serif;
                    font-size: 12.5px;
                    font-weight: 600;
                    line-height: 1;
                    text-decoration: none;
                    text-transform: uppercase;
                    vertical-align: initial;

                    &:hover {
                        background: $color-primary;
                        color: #fff !important;
                    }

                    &::before {
                        padding-right: .25em;
                        content: '\f0da';
                        font-family: "fontawesome-custom";
                    }

                    &.added {
                        display: none;
                    }
                }

                .add_to_cart_button,
                .ajax_add_to_cart,
                .button {
                    margin-left: .25em !important;
                }

                .added_to_cart {
                    @extend .product-link;
                    border-color: $color-secondary;
                    color: $color-secondary;

                    &:hover {
                        background: $gray;
                        color: #fff !important;
                    }
                }

                &.outofstock{
                    .button {
                        border-color: #bbb;
                        color: #bbb;
                        background-color: #fefefe;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}
