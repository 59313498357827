/****************************************************
********** General Sidebar & Widget Styles **********
****************************************************/
.sidebar {
    @extend .hidden-xs;
    @extend .hidden-is;

    .widget {
        margin-bottom: 1em;
    }

    h4 {
        margin-top: .25em;
    }

    ul {
        @extend .nav;

        li {
            border-bottom: 1px solid $color-tertiary;
            word-break: break-word;

            &:last-child {
                border-bottom: none;
            }

            a {
                display: block;
                padding: .75em;
            }

            // Shrink padding for parents, for better relation to children
            &.menu-item-has-children,
            &.cat-parent {
                > a {
                    padding-bottom: 0;
                }
            }

            &.back-to-shop {
                font-weight: 700;
                i {
                    margin: 0 .5em 0 -0.9em;
                }
            }

            // Styles for active menu items
            &.current-menu-item,
            &.current-post-parent,
            &.current_page_item,
            &.current_page_parent,
            &.current-cat {

                > a {
                    font-weight: bold;
                    color: $navbar-default-link-hover-color;
                    background: $navbar-default-link-hover-bg;
                }
            }

            // Shrink padding of submenu items
            ul {
                li {
                    > a {
                        padding: .5em .75em .5em 1.5em;
                    }
                }
            }
        }
    }
}
