#desktop-nav {
    @extend .hidden-xs;
    @extend .hidden-is;
    position: absolute;
    width: 100%;
    background: rgba($desktop-nav-bg, 0.7);
    transition: 350ms all ease-in-out;
    z-index: 100;

    nav {
        @extend .container;
    }

    .affix &{
        background: rgba($desktop-nav-bg, 0.9);
    }

    // Generic Menu item Styles
    > li {
        border-left: 1px solid rgba(255, 255, 255, 0.4);

        &:first-child {
            border: none;
        }
    }

    li {
        a {
            padding: $nav-link-padding;
            color: $desktop-nav-link-color;

            &:hover {
                background: $desktop-nav-link-hover-bg;
                color: $desktop-nav-link-hover-color;
                text-decoration: none;
            }

            @media screen and (max-width: $screen-sm-max) {
                font-size: $font-size-small;
                padding: 1em;
            }
        }

        // Second Nav Level
        .sub-menu {
            display: none;
            background: $desktop-nav-bg;

            li {
                font-size: $font-size-small;
            }

            @media screen and (min-width: $screen-sm-min) {
                display: block;
                visibility: hidden;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 10;
                min-width: 100%;
                transition: 0.1s .15s; /* delay of 0.15 seconds on hover off */
            }
        }
    }

    // Current Menu Item Styles
    .current-menu-item,
    .current-menu-ancestor {
        > a {
            background: $desktop-nav-link-active-bg;
            color: $desktop-nav-link-active-color;

            &:hover,
            &:active,
            &:focus {
                background: $desktop-nav-link-active-bg;
                color: $desktop-nav-link-active-color;
            }
        }
    }
}




// Open First Level Sub-Menus of the last item to the left
#desktop-nav {
    nav {
        @media screen and (min-width: $screen-sm-min) {
            > ul {
                > li:last-child {
                    .sub-menu {
                        left: auto;
                        right: 0;

                        // Open Second Level Sub-Menus of the last item to the left
                        .sub-menu {
                            right: 100%;
                        }
                    }
                }
            }

            li:hover {
                > .sub-menu {
                    visibility: visible;
                    transition-delay: 0s;
                }
            }
        }
    }
}


// Third Nav Level
#desktop-nav {
    .sub-menu {
        .sub-menu {
            @media screen and (min-width: $screen-sm-min) {
                top: 0;
                left: 100%;
            }
        }
    }
}
