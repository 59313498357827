#hero {
    .header-custom-image,
    .header-post-thumbnail,
    .header-default-image,
    .header-no-image,
    &.fl-row {
        @extend .bg-img-cover;
        @extend .hero-area-content; // @ common/_helper-classes.scss
        position: relative;

        &::after {
            display: block;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 200px;
            background-image: url(../images/leaf-separator.png);
            background-position: bottom;
            background-repeat: repeat-x;
            content: ' ';
    }

    .header-no-image {
        background: $color-primary;
        > div {
            @extend .container;
        }
        p {
            width: 100%;
            padding: 1em;
            color: #fff;
            text-align: center;

            @media screen and (min-width: $screen-sm-min) {
                font-size: 1.5em;
            }
        }
    }

        .fl-row-content {
            position: relative;
            z-index: 10;
        }
        .hero-content {
            .fl-module-content {
                .uabb-infobox-content {
                    .uabb-infobox-title-wrap {
                        font-family: $font-family-special;
                        line-height: 1;

                        .uabb-infobox-title-prefix {
                            font-size: 2.5em;
                        }
                        .uabb-infobox-title {
                            font-size: 4.75em;
                        }

                        @media screen and (min-width: $screen-sm-min) {
                            .uabb-infobox-title-prefix {
                                font-size: 4.5em;
                            }
                            .uabb-infobox-title {
                                font-size: 6.75em;
                            }
                        }
                    }
                }
                .uabb-infobox-text {
                    font-family: $font-family-serif;

                    @media screen and (min-width: $screen-sm-min) {
                        font-size: $font-size-big;
                    }
                }
            }
        }
    }
}
