.fl-module-advanced-facebook-posts-module {
    .fl-module-content {
        .advanced-facebook-posts-carousel {
            @include clearfix
            @include list-unstyled;
            height: 100%;
            font-size: $font-size-small;

            .facebook-posts-item {
                @include clearfix();
                margin: 0;
                padding: 1em;
                background: none;

                // .facebook-post-media wrapper
                > a {
                    @include make-xs-column(12);
                    @include make-is-column(4);
                    display: block;
                }
                .facebook-post-date {
                    @include make-xs-column(12);
                    @include make-is-column(8);
                    padding-bottom: .5em;
                    font-weight: 600;
                }
                .facebook-post-media {
                        display: block;
                        max-height: 250px;
                        width: auto;
                        border: 1em solid #fff;
                        box-shadow: 0 0 4px 2px rgba(0,0,0,0.1);

                        img {
                            max-width: 100% !important;
                        }
                }
                .facebook-post-link {
                    display: inline-block;
                    margin-left: .5em;
                    white-space: nowrap;
                }
                .facebook-post-message {
                    @include make-xs-column(12);
                    @include make-is-column(8);
                    padding-top: $grid-gutter-width/2;
                }

                @media screen and (min-width: $screen-is-min) {
                    .facebook-post-date {
                        padding-left: 2em;
                        float: right !important;
                    }
                    .facebook-post-message {
                        padding-top: 0;
                        padding-left: 2em;
                    }
                }
            }
        }

        .bx-wrapper {
            position: relative;

            .bx-controls {
                i {
                    color: $color-tertiary;
                    font-size: 2.5em;
                }
                .bx-prev {
                    left: -1.5em;
                }

                .bx-next {
                    right: -1.5em;
                }
            }
        }
    }
}
