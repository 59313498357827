// Table styles
.table {
    &.table-centered {
        width: auto !important;
        margin: 0 auto;

        td {
            padding: 0 .5em;

            @media screen and (max-width: $screen-xs-max) {
                display: block;
                height: auto;
                padding: .5em 0;
            }
        }
    }

    &.table-striped {
        tr {
            &:nth-child(even) {
                border-top: 1px solid $gray-lighter;
                background: rgba($gray-lighter, 0.5);
            }
        }
    }

    .weinprobe & {
        @media screen and (min-width: $screen-sm-min) {
            width: 90%;
        }
    }
}
