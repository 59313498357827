.woocommerce {
    // Generic shop_table styles (cart, wishlist)
    table {
        &.shop_table {
            font-size: $font-size-small;

            th,
            td {
                &.product-thumbnail {
                    img {
                        width: 100%;
                        min-width: 80px;
                        max-width: 100px;
                    }
                }

                &.product-remove {

                    padding: 16px 12px !important;

                    &::before {
                        content: none;
                    }

                    a.remove {
                        display: inline-block;
                        width: auto;
                        text-align: left;
                        height: 25px;
                        color: $color-primary !important;

                        @media screen and (min-width: $screen-md-min) {
                            text-align: center;
                        }

                        &:hover {
                            background: none;
                            color: $color-primary;
                        }


                        &::before {
                            display: inline-block;
                            margin: -5px 5px 0 0;
                            color: $text-color;
                            content: attr(aria-label) ": ";
                            font-size: $font-size-small;
                            font-weight: normal;
                            vertical-align: middle;
                            width: auto;

                            @media screen and (min-width: $screen-md-min) {
                                display: none;
                            }
                        }

                        &:hover {
                            @media screen and (max-width: $screen-is-max) {
                                color: $text-color !important;
                                background: none;
                            }
                        }
                    }
                }

                &.product-name {
                    .delivery-time-info {
                        font-size: $font-size-tiny;
                    }
                }

                &.product-price {
                    .unit-price {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
