// Block Grid
// Technique adapted from Foundation 5 for Bootstrap 3.
// https://github.com/zurb/foundation/blob/f755d8704123f86c281ede0b171881e2672f150d/scss/foundation/components/_block-grid.scss
// Original LESS Version by Christopher Mitchell (https://gist.github.com/ChrisTM)
// Converted to SCSS by Rasmus Jürs (https://github.com/Jursdotme)

.gallery {
    padding: 1em 0 2em;
}

main [id*="gallery-"] {
    @include clearfix();
    display: block;
    margin: 0 -($grid-gutter-width/2);
    padding: 0;
}

.gallery-item {
    display: inline;
    margin: 0;
    padding: ($grid-gutter-width/2);
    height: auto;
    float: left;
    list-style: none;
}

.gallery-caption {}

@mixin block-grid ($per-row) {
    & > .gallery-item {
        width: (100%/$per-row);

        @media screen and (max-width: $screen-xs-max) {
            width: 50%;
        }

        $nth-equation: #{$per-row}n+'+'+1;
        &:nth-of-type(n) { clear: none; }
        &:nth-of-type(#{$nth-equation}) { clear: right; }
    }
}

@mixin block-grids($size) {
    .gallery-columns-1  { @include block-grid(1); }
    .gallery-columns-2  { @include block-grid(2); }
    .gallery-columns-3  { @include block-grid(3); }
    .gallery-columns-4  { @include block-grid(4); }
    .gallery-columns-5  { @include block-grid(5); }
    .gallery-columns-6  { @include block-grid(6); }
    .gallery-columns-7  { @include block-grid(7); }
    .gallery-columns-8  { @include block-grid(8); }
    .gallery-columns-9  { @include block-grid(9); }
    .gallery-columns-10 { @include block-grid(10); }
    .gallery-columns-11 { @include block-grid(11); }
    .gallery-columns-12 { @include block-grid(12); }
}

@include block-grids(xs);
@media (min-width: $screen-sm-min) { @include block-grids(sm) }
@media (min-width: $screen-md-min) { @include block-grids(md) }
@media (min-width: $screen-lg-min) { @include block-grids(lg) }
