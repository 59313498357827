.fl-module-post-grid {
    .fl-module-content {
        .fl-post-feed {
            .fl-post-feed-post {
                max-width: none;
            }
            .fl-post-feed-header {
                margin-bottom: 0;
            }
        }
    }
}
