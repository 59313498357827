.woocommerce-checkout {

    // Start bugfix for cart content strange flickering after recalculation shipping cost
    @media screen and (max-width: $screen-is-max) {
        height: auto;

        .topbar {
            position: static;
        }

        .page-wrap {
            padding: 0;
        }

        .right-off-canvas-menu {
            margin-top: -55px;
        }
    }
    // End bugfix

    h3 {
        font-size: $font-size-large;
    }

    form.checkout {
        .woocommerce-checkout-review-order-table {
            td {
                &.product-name {
                    .wc-gzd-product-name-left {
                        display: none;
                    }
                    @media screen and (min-width: $screen-is-min) {
                        .wc-gzd-product-name-left {
                            display: block;
                        }
                        .wc-gzd-product-name-right {
                            max-width: 75%;
                        }
                    }
                }
            }
        }
    }

    .woocommerce-shipping-fields {
        #ship-to-different-address {
            label {
                padding-left: 24px;
            }
        }
    }
}

.woocommerce-order {
    .woocommerce-notice {
        &.woocommerce-notice--success {
            font-size: $font-size-h2;
            color: $color-success;
        }
    }
    .woocommerce-order-overview {
        li {
            margin-bottom: .5em;
        }
    }
    .shipped_via {
        @media screen and (max-width: $screen-is-max) {
            font-size: 10px;
        }
    }
}
